import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import DOMPurify from 'dompurify';
import Util from './util';
import Constants from './constants/Constants';
import NetworkErrorModal from './NetworkErrorModal';

const user = Util.readObjectFromCookies(process.env.REACT_APP_SEARCH_STUDIO__SESSION_USER_KEY);

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test' && user) {
  Sentry.init({
    dsn: 'https://1f49a10ec39c4f73bb70fd2a05600541@o208556.ingest.sentry.io/5661244',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showErrorModal: false,
      eventId: null,
    };
  }

  // Catches errors and sets the error states
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
      this.setState({
        error,
        showErrorModal: true,
      });

      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        if (user) {
          scope.setUser({
            id: user._id,
            userName: user.username,
            email: user.email,
          });
        }
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    } else {
      this.setState({
        showErrorModal: true,
        error,
        eventId: errorInfo,
      });
    }
  }

  /**
   * Runs when send feedback button on the error modal is clicked
   * @returns {void}
   */
  handleReportFeedBack = () => {
    // check the environment is not development and test
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
      const { eventId } = this.state;

      // close the error modal
      this.setState({
        showErrorModal: false,
        sentryModalFeedbackMessage: '',
      });

      // open the feedback modal of the Sentry
      Sentry.showReportDialog({ eventId });
      const { showErrorModal } = this.state;

      /**
       * Start interval
       * Interval is used here to check if form is submitted or closed
       */
      this.interval = setInterval(() => {
        // get the Sentry modal as html element
        const sentryModal = document.getElementsByClassName('sentry-error-embed');

        /**
         * Get the feedback message
         * It will not be undefined only when form is filled and submitted
         */
        const sentryModalFeedbackMessage = document.getElementsByClassName('message-success');
        if (sentryModalFeedbackMessage) {
          this.setState({
            sentryModalFeedbackMessage: 'Your feedback has been sent. Thank you!',
          });
        }

        // if the Sentry modal is closed then open the error modal again with success feedback message
        if ((!sentryModal || sentryModal.length === 0) && showErrorModal) {
          this.setState({
            showErrorModal: true,
          });
          clearInterval(this.interval);
        }
      }, 1000);
    }
  }

  /**
   * Reset error states
   * @returns {void} Will setState for error, eventId, navigator, globalNavigator and sentryModalFeedbackMessage
   */
  cancelError = () => {
    this.setState({
      error: false,
      eventId: null,
      // eslint-disable-next-line react/no-unused-state
      navigator: Constants.NAVIGATION__OVERVIEW,
      sentryModalFeedbackMessage: '',
      showErrorModal: false,
    });
  };

  render() {
    const { error, showErrorModal, sentryModalFeedbackMessage } = this.state;
    const { children } = this.props;

    /**
     * Check if error is a 'network error'
     */
    if (error && error.toString() === Constants.NETWORK_ERROR) {
      return (
        <NetworkErrorModal error cancelError={this.cancelError} />
      );
    }

    // For other type of error
    if (error) {
      return (
        <>
          <section
            role="dialog"
            tabIndex="-1"
            className="slds-modal slds-fade-in-open slds-modal_small other-than-network-error-modal"
            aria-labelledby="modal-heading-01"
            aria-modal="true"
            aria-hidden={showErrorModal}
          >
            <div className="slds-modal__container">
              <header className="slds-modal__header">
                <h2 id="modal-heading-01" className="slds-modal__title slds-hyphenate">Oops</h2>
              </header>
              <div className="slds-modal__content slds-p-around_medium" style={{ textAlign: 'center' }}>
                <p>{sentryModalFeedbackMessage || 'Something didn\'t quite go as planned'}</p>
                <img alt="Penguin" src="./img/sad-penguin.png" height="300px" width="300px" />
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={({
                  __html: DOMPurify.sanitize(error?.response?.data?.error || error).replace('<a', '<a target="_blank"'),
                })}
                />
              </div>
              <footer className="slds-modal__footer">
                <button
                  type="submit"
                  className="slds-button slds-button_neutral"
                  onClick={() => this.cancelError()}
                >
                  Go back
                </button>
                <button
                  type="submit"
                  className="slds-button slds-button_neutral"
                  onClick={() => this.handleReportFeedBack()}
                >
                  Report Feedback
                </button>
              </footer>
            </div>
          </section>
          <div className="slds-backdrop slds-backdrop_open" />
        </>
      );
    }

    // if there is no error, return children
    return children;
  }
}

ErrorBoundary.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export default ErrorBoundary;
