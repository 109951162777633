import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import SearchForm from '../../components/search/Search';

class Overview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const {
      handleSetAppState,
      user,
      isClickedFirstTime,
    } = this.props;
    return (
      <div className="overview">
        <SearchForm
          handleSetAppState={handleSetAppState}
          user={user}
          isClickedFirstTime={isClickedFirstTime}
        />
      </div>
    );
  }
}

Overview.propTypes = {
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * object of current user
   * This prop will be passed from App.js component
   */
  user: PropTypes.instanceOf(Object).isRequired,
  /**
   * Responsible for showing/hiding data table when clicked
   * first time
   */
  isClickedFirstTime: PropTypes.bool.isRequired,
};

export default Overview;
