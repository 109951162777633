import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Constants from '../../constants/Constants';

import './styles.scss';

const Results = ({
  data,
  marketingCloudStackNumber,
  isClickedFirstTime,
  loading,
  currentBusinessUnitId,
  isCurrentBusinessUnitId,
  handleSetSearchState,
}) => {
  let result;

  /*
   * if isCurrentBusinessUnitId filter data of current client
   * else show all data
   */
  if (isCurrentBusinessUnitId) {
    result = data.filter(item => item.ClientID === currentBusinessUnitId);
  } else {
    result = data;
  }

  // check if data is available to show
  const isData = result && result.length > 0;

  /**
   * onChange event handler for current business unit id toggle checkbox
   * @returns {void}
   */
  const handleToggleBusinessUnitCheckbox = () => {
    handleSetSearchState(prevState => ({ isCurrentBusinessUnitId: !prevState.isCurrentBusinessUnitId }));
  };

  /*
   * render Business Unit Checkbox to filter data based on current
   * business unit
   */
  const renderBusinessIdCheckbox = () => (
    <div>
      <div className="slds-form-element">
        <div className="slds-form-element__control">
          <div className="slds-checkbox">
            <label className="slds-checkbox__label" htmlFor="current-BU-id-checkbox">
              <input
                type="checkbox"
                name="business"
                id="current-BU-id-checkbox"
                value={isCurrentBusinessUnitId}
                checked={isCurrentBusinessUnitId}
                onChange={handleToggleBusinessUnitCheckbox}
              />
              <span className="slds-checkbox_faux" />
              <span className="slds-form-element__label">Limit to results of current business unit only</span>
            </label>
          </div>
        </div>
      </div>
      <br />
    </div>
  );

  /**
   * renders view based on data
   * @returns {void}
   */
  const renderTable = () => {
    // if loading is true show spinner
    if (loading) {
      return (
        <div className="demo-only demo-only_viewport" style={{ height: '55vh', position: 'relative' }}>
          <div role="status" className="slds-spinner slds-spinner_medium">
            <span className="slds-assistive-text">Loading</span>
            <div className="slds-spinner__dot-a" />
            <div className="slds-spinner__dot-b" />
          </div>
        </div>
      );
    }

    // if data is fetched and set show tabular data
    if (isData) {
      return (
        <div>
          {renderBusinessIdCheckbox()}
          <Scrollbars
            style={{ height: '60vh' }}
            autoHide
          >
            <table id="data-table" className="slds-table slds-table_cell-buffer slds-table_bordered">
              <thead>
                <tr className="slds-line-height_reset">
                  <th className="" scope="col">
                    <div className="slds-truncate" title="Name">
                      <span className="cell-with-popup">Name</span>
                      <Popup
                        content="You can open the Data Extensions in
                      this business unit by clicking on the Data Extension name."
                        size="small"
                        position="top left"
                        trigger={<Icon circular name="info" size="tiny" color="grey" inverted />}
                      />
                    </div>
                  </th>
                  <th className="" scope="col">
                    <div className="slds-truncate" title="Location">Location</div>
                  </th>
                  <th className="" scope="col">
                    <div className="slds-truncate" title="Business unit">Business unit</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {result.map((el, index) => (
                  <tr key={el.ObjectID || el.OBJName + index} className="slds-hint-parent">

                    <td data-label="Name">
                      {
                        el.type === Constants.OBJECT_TYPE__DATA_EXTENSION &&
                        el.ClientID === currentBusinessUnitId && marketingCloudStackNumber ?
                          (
                            <a
                              href={(`https://mc.${marketingCloudStackNumber}
                        .exacttarget.com/contactsmeta/admin.html#admin/data-extension
                        /${el.ObjectID}/properties/`).replace(/\s+/g, '')}
                              target="_blank"
                              rel="noreferrer"
                              className="slds-truncate"
                              title={el.OBJName}
                            >
                              {el.OBJName}
                            </a>
                          ) :
                          (
                            <div className="slds-truncate" title={el.OBJName}>
                              {el.OBJName}
                            </div>
                          )
}

                    </td>

                    <td data-label="Location">
                      <div className="slds-truncate" title={el.location}>
                        {el.location}
                      </div>
                    </td>

                    <td data-label="Location">
                      <div className="slds-truncate" title={el.ClientID}>
                        {el.ClientID}
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </Scrollbars>
        </div>
      );
    }
    // if no data fetched show not found
    return (
      <div>
        {renderBusinessIdCheckbox()}
        <span className="no-results">No results found</span>
      </div>
    );
  };
  return (
    <div className={'results-container' + (isClickedFirstTime ? ' results-container-focus' : '')}>
      {renderTable()}
    </div>
  );
};

Results.propTypes = {
  /**
   * It keeps data
   * It will be passed from Search.js
   */
  data: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps marketing cloud stack number
   */
  marketingCloudStackNumber: PropTypes.string.isRequired,
  /**
   * Responsible for showing/hiding data table when clicked
   * first time
   */
  isClickedFirstTime: PropTypes.bool.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  loading: PropTypes.bool.isRequired,
  /**
   * Responsible for filtering data table for
   * current BU
   */
  isCurrentBusinessUnitId: PropTypes.bool.isRequired,
  /**
   * current business unit Id
   * It will be passed from Search.js
   */
  currentBusinessUnitId: PropTypes.number.isRequired,
  /**
   * it sets the Search component`s state
   * This prop will be passed from Search.js component
   */
  handleSetSearchState: PropTypes.func.isRequired,

};

export default Results;
