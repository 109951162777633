const Constants = {
  // Deselect
  DESELECT__WEBSITE_LINK: 'https://deselect.com/',

  // Network
  NETWORK_ONLINE: 'online',
  NETWORK_OFFLINE: 'offline',
  NETWORK_ERROR: 'Network Error',

  // Navigation
  NAVIGATION__OVERVIEW: 'overview',

  // Object Type
  OBJECT_TYPE__DATA_EXTENSION: 'dataextension',
  OBJECT_TYPE__DATA_EXTENSION_LABEL: 'Data Extension',

  OBJECT_TYPE__AUTOMATION: 'automation',
  OBJECT_TYPE__AUTOMATION_LABEL: 'Automation',

  OBJECT_TYPE__CLASSIC_EMAIL: 'email',
  OBJECT_TYPE__CLASSIC_EMAIL_LABEL: 'Classic Email',

  OBJECT_TYPE__QUERY_DEFINITION: 'queryactivity',
  OBJECT_TYPE__QUERY_DEFINITION_LABEL: 'Query Activity',

  OBJECT_TYPE__USER_INITIATED_SEND: 'userinitiatedsend',
  OBJECT_TYPE__USER_INITIATED_SEND_LABEL: 'User Initiated Send',

  OBJECT_TYPE__FILTER_DEFINITION: 'filterdefinition',
  OBJECT_TYPE__FILTER_DEFINITION_LABEL: 'Data Filter',

  OBJECT_TYPE__CONTENT: 'content',
  OBJECT_TYPE__CONTENT_BUILDER__ALL_TYPES: 'Content Builder',
  OBJECT_TYPE__CONTENT_BUILDER__EMAIL_TEMPLATE: 'Email Template',
  OBJECT_TYPE__CONTENT_BUILDER__EMAIL: 'Email Message',
  OBJECT_TYPE__CONTENT_BUILDER__IMAGE: 'Image',
  OBJECT_TYPE__CONTENT_BUILDER__RAW_IMAGE: 'Raw Image',
  OBJECT_TYPE__CONTENT_BUILDER__VIDEO: 'Video',
  OBJECT_TYPE__CONTENT_BUILDER__DOCUMENT: 'Document',
  OBJECT_TYPE__CONTENT_BUILDER__AUDIO: 'Audio',
  OBJECT_TYPE__CONTENT_BUILDER__ARCHIVE: 'Archive',
  OBJECT_TYPE__CONTENT_BUILDER__CODE: 'Code',
  OBJECT_TYPE__CONTENT_BUILDER__TEXT_FILE: 'Text File',
  OBJECT_TYPE__CONTENT_BUILDER__ALL_BLOCKS: 'Content Blocks',
  OBJECT_TYPE__CONTENT_BUILDER__DYNAMIC_CONTENT_BLOCK: 'Dynamic Content Block',
  OBJECT_TYPE__CONTENT_BUILDER__BUTTON_BLOCK: 'Button Block',
  OBJECT_TYPE__CONTENT_BUILDER__CODE_SNIPPET_BLOCK: 'Code Snippet Block',
  OBJECT_TYPE__CONTENT_BUILDER__ENHANCED_DYNAMIC_CONTENT_BLOCK: 'Enhanced Dynamic Content Block',
  OBJECT_TYPE__CONTENT_BUILDER__FREE_FORM_BLOCK: 'Free Form Block',
  OBJECT_TYPE__CONTENT_BUILDER__EXTERNAL_CONTENT_BLOCK: 'External Content Block',
  OBJECT_TYPE__CONTENT_BUILDER__HTML_BLOCK: 'HTML Block',
  OBJECT_TYPE__CONTENT_BUILDER__IMAGE_BLOCK: 'Image Block',
  OBJECT_TYPE__CONTENT_BUILDER__IMAGE_CAROUSEL_BLOCK: 'Image Carousel Block',
  OBJECT_TYPE__CONTENT_BUILDER__LAYOUT_BLOCK: 'Layout Block',
  OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_FOLLOW_BLOCK: 'Social Follow Block',
  OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_SHARE_BLOCK: 'Social Share Block',
  OBJECT_TYPE__CONTENT_BUILDER__TEXT_BLOCK: 'Text Block',

  // Object Types
  OBJECT_TYPE__CONTENT_CATEGORY: 'category',
  OBJECT_TYPE__FOLDER: 'folder',

  // Asset Types
  CONTENT__ASSET_TYPE__ALL_TYPES: 'all-types',
  CONTENT__ASSET_TYPE__EMAIL_TEMPLATE: 'email-template',
  CONTENT__ASSET_TYPE__EMAIL: 'email',
  CONTENT__ASSET_TYPE__IMAGE: 'image',
  CONTENT__ASSET_TYPE__RAW_IMAGE: 'raw-image',
  CONTENT__ASSET_TYPE__VIDEO: 'video',
  CONTENT__ASSET_TYPE__DOCUMENT: 'document',
  CONTENT__ASSET_TYPE__AUDIO: 'audio',
  CONTENT__ASSET_TYPE__ARCHIVE: 'archive',
  CONTENT__ASSET_TYPE__CODE: 'code',
  CONTENT__ASSET_TYPE__TEXT_FILE: 'text-file',
  CONTENT__ASSET_TYPE__ALL_CONTENT_BLOCKS: 'all-blocks',
  CONTENT__ASSET_TYPE__DYNAMIC_BLOCK: 'dynamic-content-block', // Dynamic Content Block
  CONTENT__ASSET_TYPE__BUTTON_BLOCK: 'button-block', // Button Block
  CONTENT__ASSET_TYPE__CODE_SNIPPET_BLOCK: 'code-snippet-block', // Code Snippet Block
  // Enhanced Dynamic Content Block
  CONTENT__ASSET_TYPE__ENHANCED_DYNAMIC_CONTENT_BLOCK: 'enhanced-dynamic-content-block',
  CONTENT__ASSET_TYPE__EXTERNAL_CONTENT_BLOCK: 'external-content-block', // External Content Block
  CONTENT__ASSET_TYPE__FREE_FORM_BLOCK: 'free-form-block', // Free Form Block
  CONTENT__ASSET_TYPE__HTML_BLOCK: 'html-block', // HTML Block
  CONTENT__ASSET_TYPE__IMAGE_BLOCK: 'image-block', // Image Block
  CONTENT__ASSET_TYPE__IMAGE_CAROUSEL_BLOCK: 'image-carousel-block', // Image Carousel Block
  CONTENT__ASSET_TYPE__LAYOUT_BLOCK: 'layout-block', // Layout Block
  CONTENT__ASSET_TYPE__SOCIAL_FOLLOW_BLOCK: 'social-follow-block', // Social Follow Block
  CONTENT__ASSET_TYPE__SOCIAL_SHARE_BLOCK: 'social-share-block', // Social Share Block
  CONTENT__ASSET_TYPE__TEXT_BLOCK: 'text-block', // Text Block
  CONTENT__ASSET_TYPE__JSON_MESSAGE: 'json-message', // JSON Message = Push Notification and Mobile Message
  CONTENT__ASSET_TYPE__TEXT_ONLY_EMAIL: 'textonlyemail',
  CONTENT__ASSET_TYPE__TEMPLATE_BASED_EMAIL: 'templatebasedemail',

  // Search by
  OBJECT_TYPE__SEARCH_BY__NAME: 'Name',
  OBJECT_TYPE__SEARCH_BY__CUSTOMER_KEY: 'CustomerKey',

  // Folder Types
  FOLDER_TYPE__DATA_EXTENSION: 'dataextension',
  FOLDER_TYPE__SHARED_DATA: 'shared_data',
  FOLDER_TYPE__SHARED_DATA_EXTENSION: 'shared_dataextension',
  FOLDER_TYPE__SALESFORCE_DATA_EXTENSION: 'salesforcedataextension',
  FOLDER_TYPE__SHARED_SALESFORCE_DATA_EXTENSION: 'shared_salesforcedataextension',
  FOLDER_TYPE__SYNCHRONIZED_DATA_EXTENSION: 'synchronizeddataextension',
  FOLDER_TYPE__EMAIL: 'email',
  FOLDER_TYPE__SHARED_EMAIL: 'shared_email',
  FOLDER_TYPE__AUTOMATIONS: 'automations', // Not a mistake, really needs to be plural
  FOLDER_TYPE__ASSET: 'asset',
  FOLDER_TYPE__FILTER_DEFINITION: 'filterdefinition',
  FOLDER_TYPE__QUERY_ACTIVITY: 'queryactivity',
  FOLDER_TYPE__TEMPLATE: 'template',
  FOLDER_TYPE__SHARED_TEMPLATE: 'shared_template',
  FOLDER_TYPE__USER_INITIATED_SENDS: 'userinitiatedsends', // Not a mistake, really needs to be plural

  // Input Types
  INPUT_TYPE__ENTER: 'Enter',

  // App names based on environment
  DESELECT_SEGMENT_STAGING_APP_NAME: 'DESelect%20Staging',
  DESELECT_SEGMENT_RELEASE_APP_NAME: 'DESelect%20Release',
  DESELECT_SEGMENT_PRODUCTION_APP_NAME: 'DESelect%20Segment',

  DESELECT_ENGAGE_STAGING_APP_NAME: 'DESelect%20Engage%20Staging',
  /**
   * We don't have release env for engage, so we use the same link as staging
   */
  DESELECT_ENGAGE_RELEASE_APP_NAME: 'DESelect%20Engage%20Staging',
  DESELECT_ENGAGE_PRODUCTION_APP_NAME: 'DESelect%20Engage',
};

export default Constants;
