/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Dropdown, Input, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Results from './Results';
import Constants from '../../constants/Constants';
import SearchAPI from '../../api/search';
import './styles.scss';
import 'semantic-ui-css/semantic.min.css';

class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      objectType: '',
      searchQuery: '',
      data: [],
      marketingCloudStackNumber: '',
      loading: false,
      searchBy: Constants.OBJECT_TYPE__SEARCH_BY__NAME,
      contentType: '',
      locationPath: [],
      selectedObjectType: '',
      currentBusinessUnitId: props.user && props.user.loggedInBusinessUnitId ? props.user.loggedInBusinessUnitId : 0,
      isCurrentBusinessUnitId: false,
      objectTypesOptions: [{
        text: Constants.OBJECT_TYPE__AUTOMATION_LABEL,
        value: Constants.OBJECT_TYPE__AUTOMATION,
        contentType: Constants.FOLDER_TYPE__AUTOMATIONS,
        hasChildren: false,
      },
      {
        text: Constants.OBJECT_TYPE__DATA_EXTENSION_LABEL,
        value: Constants.OBJECT_TYPE__DATA_EXTENSION,
        contentType: Constants.FOLDER_TYPE__DATA_EXTENSION,
        hasChildren: false,
      },
      {
        key: Constants.OBJECT_TYPE__CLASSIC_EMAIL,
        text: Constants.OBJECT_TYPE__CLASSIC_EMAIL_LABEL,
        value: Constants.OBJECT_TYPE__CLASSIC_EMAIL,
        contentType: Constants.FOLDER_TYPE__EMAIL,
        hasChildren: false,
      },
      {
        text: Constants.OBJECT_TYPE__FILTER_DEFINITION_LABEL,
        value: Constants.OBJECT_TYPE__FILTER_DEFINITION,
        contentType: Constants.FOLDER_TYPE__FILTER_DEFINITION,
        hasChildren: false,
      },
      {
        text: Constants.OBJECT_TYPE__QUERY_DEFINITION_LABEL,
        value: Constants.OBJECT_TYPE__QUERY_DEFINITION,
        contentType: Constants.FOLDER_TYPE__QUERY_ACTIVITY,
        hasChildren: false,
      },
      {
        text: Constants.OBJECT_TYPE__USER_INITIATED_SEND_LABEL,
        value: Constants.OBJECT_TYPE__USER_INITIATED_SEND,
        contentType: Constants.FOLDER_TYPE__USER_INITIATED_SENDS,
        hasChildren: false,
      },
      {
        key: Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_TYPES,
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_TYPES,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_TYPES,
        assetType: Constants.CONTENT__ASSET_TYPE__ALL_TYPES,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: true,
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__AUDIO,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__AUDIO,
        assetType: Constants.CONTENT__ASSET_TYPE__AUDIO,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__ARCHIVE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__ARCHIVE,
        assetType: Constants.CONTENT__ASSET_TYPE__ARCHIVE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE,
        assetType: Constants.CONTENT__ASSET_TYPE__CODE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__DOCUMENT,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__DOCUMENT,
        assetType: Constants.CONTENT__ASSET_TYPE__DOCUMENT,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL,
        assetType: Constants.CONTENT__ASSET_TYPE__EMAIL,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL_TEMPLATE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL_TEMPLATE,
        assetType: Constants.CONTENT__ASSET_TYPE__EMAIL_TEMPLATE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE,
        assetType: Constants.CONTENT__ASSET_TYPE__IMAGE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__RAW_IMAGE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__RAW_IMAGE,
        assetType: Constants.CONTENT__ASSET_TYPE__RAW_IMAGE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_FILE,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_FILE,
        assetType: Constants.CONTENT__ASSET_TYPE__TEXT_FILE,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__VIDEO,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__VIDEO,
        assetType: Constants.CONTENT__ASSET_TYPE__VIDEO,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_BLOCKS,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_BLOCKS,
        assetType: Constants.CONTENT__ASSET_TYPE__ALL_CONTENT_BLOCKS,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: true,
        depth: 'one-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__BUTTON_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__BUTTON_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__BUTTON_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE_SNIPPET_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE_SNIPPET_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__CODE_SNIPPET_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__DYNAMIC_CONTENT_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__DYNAMIC_CONTENT_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__DYNAMIC_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__ENHANCED_DYNAMIC_CONTENT_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__ENHANCED_DYNAMIC_CONTENT_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__ENHANCED_DYNAMIC_CONTENT_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__EXTERNAL_CONTENT_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__EXTERNAL_CONTENT_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__EXTERNAL_CONTENT_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__FREE_FORM_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__FREE_FORM_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__FREE_FORM_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        key: Constants.OBJECT_TYPE__CONTENT_BUILDER__HTML_BLOCK,
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__HTML_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__HTML_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__HTML_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__IMAGE_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        key: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_CAROUSEL_BLOCK,
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_CAROUSEL_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_CAROUSEL_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__IMAGE_CAROUSEL_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__LAYOUT_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__LAYOUT_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__LAYOUT_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        key: Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_FOLLOW_BLOCK,
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_FOLLOW_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_FOLLOW_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__SOCIAL_FOLLOW_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_SHARE_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_SHARE_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__SOCIAL_SHARE_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      },
      {
        key: Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_BLOCK,
        text: Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_BLOCK,
        value: Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_BLOCK,
        assetType: Constants.CONTENT__ASSET_TYPE__TEXT_BLOCK,
        contentType: Constants.FOLDER_TYPE__ASSET,
        hasChildren: false,
        depth: 'two-level-depth',
      }],
    };

    this.axiosCancelToken = axios.CancelToken.source();
  }

  componentDidMount() {
    this.getHomePageMessage();
  }

  /**
   * get home message
   * @returns {void}
   */
  getHomePageMessage = async () => {
    const { isClickedFirstTime } = this.props;
    const { data } = await SearchAPI.getHomePageMessage();

    if (data?.isActive && !isClickedFirstTime) {
      document.getElementById('home-page-message').innerHTML = data?.text.replace(/\u200B/g, '');
      document.getElementById('home-page-message').children[0].style = '';
    }
  }

  /**
   * onChange event handler for objectType
   * @param {object} e - event
   * @param {object} data - dropdown data
   * @returns {void}
   */
  handleObjectTypeChange = (e, data) => {
    const { value } = data;
    const { objectTypesOptions } = this.state;

    // get assestype and contenttype based on selected value
    const { assetType, contentType } = objectTypesOptions.find(opt => opt.value === value);

    // get objectType value
    const objType = this.getContentObjectType(value);
    this.setState({
      selectedObjectType: value,
      objectType: objType,
      assetType: assetType || '',
      contentType: contentType || '',
    });
  }

  /**
   * get object type based on selected object if
   * object type is content category , set objectType
   *  content else selected objectType
   * @param {string} type - object type
   * @returns {string} - result
   */
  getContentObjectType = (type) => {
    let result = type;
    const objectTypes = [
      Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL_TEMPLATE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__EMAIL,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__DYNAMIC_CONTENT_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__BUTTON_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE_SNIPPET_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__ENHANCED_DYNAMIC_CONTENT_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__FREE_FORM_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__EXTERNAL_CONTENT_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__HTML_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__RAW_IMAGE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__VIDEO,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__DOCUMENT,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__AUDIO,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__ARCHIVE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__CODE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_FILE,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__IMAGE_CAROUSEL_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__LAYOUT_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_FOLLOW_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__SOCIAL_SHARE_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__TEXT_BLOCK,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_TYPES,
      Constants.OBJECT_TYPE__CONTENT_BUILDER__ALL_BLOCKS,
    ];
    objectTypes.forEach((el) => {
      if (type === el) { result = Constants.OBJECT_TYPE__CONTENT; }
    });

    return result;
  }

  /**
   * onChange event handler for search term
   * @param {object} e - event
   * @param {object} data - input field data
   * @returns {void}
   */
  handleSearchQueryChange = (e, data) => {
    const { value } = data;
    this.setState({ searchQuery: value });
  }

  /**
   * onClick event handler for search
   * @returns {void}
   */
  handleSearchClick = async () => {
    const {
      objectType,
      searchQuery,
      searchBy,
      contentType,
      assetType,
    } = this.state;

    const { isClickedFirstTime, handleSetAppState } = this.props;
    try {
      setTimeout(() => {
        const searchContainer = document.getElementById('search-container');
        searchContainer.style.marginLeft = 'auto';

        const navBar = document.getElementById('navbar');
        navBar.style.marginLeft = 'auto';
      }, 2000);

      // set loading spinner visible and empty data array
      this.setState({ loading: true, locationPath: [], data: [] });

      /*
       * if clicked first time change view of screen with search box on top and data
       * table on bottom
       */
      if (!isClickedFirstTime) {
        handleSetAppState({ isClickedFirstTime: true });
      }

      // get results from api based on given data
      const results = await SearchAPI.getResults(objectType, searchQuery, searchBy, assetType, contentType,
        this.axiosCancelToken.token);

      // if no results return do not call folders
      if (results && results.results && results.results.length > 0) {
        /*
         * if results length is greater than 0 call folders based on given content
         * type
         */
        const folders = await SearchAPI.getResults(Constants.OBJECT_TYPE__FOLDER,
          searchQuery, searchBy, assetType, contentType,
          this.axiosCancelToken.token);

        // If the folders were found then format data
        if (folders?.results?.length) {
          /**
           * Create two objects, one that stores for each folder id its parent folder id
           * and one that for each folder stores its details (name and clientId)
           */
          const foldersDetails = this.buildFoldersTree(folders.results);

          // Get formatted results to show to the user
          const data = this.formatData(results.results, foldersDetails);

          const marketingCloudStackNumber = this.getMarketingCloudStackNumber(results);

          // Load data to state
          this.setState({ data, marketingCloudStackNumber });
        }

        // set loading spinner invisible
        this.setState({ loading: false });
      } else {
        // set spinner invisible and data empty
        this.setState({ data: [], loading: false });
      }
    } catch (error) {
      if (String(error?.response?.data?.actualError).includes('API Permission Failed')) {
        this.setState({ loading: false });

        const title = 'Insufficient Permissions';
        /* eslint-disable max-len */
        const paragraph = `
        Your SFMC user does not have sufficient permissions to perform this action. <br>
        Please make sure your SFMC user has all the required permissions listed 
        <a href="https://support.deselect.com/hc/en-us/articles/360005842737-Required-Permissions-for-DESelect-Search-in-SFMC-Salesforce-Marketing-Cloud-users"
        target="_blank">here</a>.
        `;
        Swal.fire({
          type: 'error',
          title: `<div class="error-title">${title}</div>`,
          html:
            `<p class="width_swal">
              ${paragraph}
            </p>`,
          // Remove all ways to close the alert
          showCancelButton: false,
          showConfirmButton: true,
          showCloseButton: false,
          allowOutsideClick: false,
          footer: '<div></div>',
        });
      } else {
        // if some error returned from API show in pop up
        this.setState({ loading: false });
        handleSetAppState({ error });
      }
    }
  };

  /**
   * Creates two objects, one that stores for each folder id its parent folder id
   * and one that for each folder stores its details (name and clientId)
   * @param {array} folders - folders returned from the API
   * @returns {object} - contains two objects with key value pairs:
   * 1. The 'tree' property - object contains folder id - parent folder id pairs.
   * 2. The 'dict' property - object contains folder id - name & client id pairs
   */
  buildFoldersTree = (folders) => {
    // Initialize objects
    const foldersTree = {};
    const foldersDict = {};

    // Loop through fetched folders
    folders.forEach((folder) => {
      // Find folder id
      const folderId = folder.ID || folder.id;

      // Find parent folder id and store it in dict
      foldersTree[folderId] = folder?.ParentFolder?.ID || folder.parentId || 0;

      // Store folder's details in the dict
      foldersDict[folderId] = {
        name: folder.Name || folder.name,
        clientId: folder?.Client?.ID || folder?.memberId || folder?.clientId,
      };
    });

    return { tree: foldersTree, dict: foldersDict };
  }

  /**
   * this function format data
   * @param {array} results - API results array
   * @param {object} foldersDetails - object containing folder's details
   * @returns {array} - contains all the row data, formatted with only the required properties
   */

  formatData = (results, foldersDetails) => {
    // Get location path from state
    const { locationPath, objectType } = this.state;

    // loop through each element of array
    results.forEach((result) => {
      /**
       * Find folder's id
       * SOAP returns it as CategoryID
       * REST normally returns it as categoryId
       * REST assets returns it as an object with an id property
       */
      const folderId =
        result?.CategoryID || result?.categoryId || result?.category?.id;

      // Get result's client id
      const clientId =
        foldersDetails.dict[folderId]?.clientId ||
        result?.Client?.ID ||
        result?.memberId ||
        'Business unit not found';

      /**
       * Get result object's name
       * It can be either the TemplateName, the Name or the name property
       */
      const objName = result?.TemplateName || result?.Name || result?.name;

      // Build a string with the full path to the folder
      const folderPath = this.buildFoldersPath(folderId, foldersDetails.tree, foldersDetails.dict);

      // push each row in the array of locationPath
      locationPath.push({
        ObjectID: result.ObjectID,
        OBJName: objName,
        location: folderPath,
        ClientID: clientId,
        type: objectType,
      });
    });

    // return row data
    return locationPath;
  }

  /**
   * this function gets the marketingCloudStackNumber
   * @param {object} foldersDetails - object containing results from search
   * @returns {string} - contains the marketingCloudStackNumber
   */

  getMarketingCloudStackNumber = (data) => {
    const { marketingCloudStackNumber } = data;

    return marketingCloudStackNumber;
  }

  /**
   * Build a folder's full path recursively
   * @param {string} folderId - id of the last folder in the path
   * @param {object} tree - object containing key - value pairs to find the parent folder of each folder
   * @param {object} dict - object containing key - value pairs for all folders (key: id, value: {name, clientId})
   * @param {string} path - folder's path
   * @returns {string} - returns full path of folder
   */
  buildFoldersPath = (folderId, tree, dict, path = '') => {
    // If current folder id is the root one, terminate
    if (folderId === 0 || !dict[folderId]?.name === 'Shared Items') return path;

    // If folder id does not exist, show custom message
    if (!folderId) return 'Location not found';

    // Add parent folder to path
    path = dict[folderId]?.name + '/' + path;

    // Call the same function again to add parent's name to the path
    return this.buildFoldersPath(tree[folderId], tree, dict, path);
  }

  /**
   * onChange event handler for search by option
   * @param {object} event - event
   * @returns {void}
   */
  handleChangeSearchByOption = (event) => {
    const { value } = event.target;
    this.setState({ searchBy: value });
  };

  /**
   * Execute every time the user inputs something into the input field to check for ENTER
   * @param {object} e - event
   * @returns {void}
   */
  inputOnKeyDown = (e) => {
    const { objectType, searchQuery } = this.state;

    // if objectType, query has value and enter key is pressed
    if (objectType && searchQuery && e.key === Constants.INPUT_TYPE__ENTER) {
      // after pressing the enter, submit search query
      this.handleSearchClick();
    }
  };

  /**
   * Function to set the state of Search component from child components
   * @param {object} searchValueUpdate - the updated state piece
   * @returns {void}
   */
  handleSetSearchState = (searchValueUpdate) => {
    this.setState(searchValueUpdate);
  }

  render() {
    const {
      searchQuery,
      data,
      loading,
      searchBy,
      selectedObjectType,
      objectTypesOptions,
      isCurrentBusinessUnitId,
      currentBusinessUnitId,
      marketingCloudStackNumber,
    } = this.state;

    const { isClickedFirstTime } = this.props;

    // show warning when objectType is automation
    const automationWarningText = 'Only searching for the exact name is available when searching for Automations.';

    // object type options to be populated
    const objectTypeOptions = objectTypesOptions
      .map(obj => ({
        value: obj.value,
        content: () => (
          <Dropdown.Item className={obj.depth}>
            <Icon className={obj.hasChildren ? 'angle down' : ''} />
            {obj.text.toString()}
          </Dropdown.Item>
        ),
        text: obj.text.toString(),
        key: obj.value,
      }));

    return (
      <>
        <div className="flex-div">
          <div
            id="search-container"
            className={'search-container' + (isClickedFirstTime ? ' search-container-focus' : '')}
          >
            <div className="slds-form">
              <div className="slds-form-element">
                <span
                  className="slds-form-element__label dropdown-label"
                  htmlFor="select-01"
                >
                  What are you looking for?
                </span>
                <Dropdown
                  id="object-type-select"
                  search
                  onChange={this.handleObjectTypeChange}
                  className="searchable-dropdown"
                  fluid
                  selection
                  placeholder="Select an object"
                  options={objectTypeOptions}
                  value={selectedObjectType}
                  onKeyDown={this.inputOnKeyDown}
                />
              </div>
              <div className="slds-form-element">
                <span className="slds-form-element__label dropdown-label">Term</span>
                {selectedObjectType === Constants.OBJECT_TYPE__AUTOMATION ?
                  (
                    <span className="warning-text">{automationWarningText}</span>
                  ) :
                  null}
                <Input
                  id="search-term-input"
                  placeholder="Search term"
                  fluid
                  value={searchQuery}
                  onChange={this.handleSearchQueryChange}
                  onKeyDown={this.inputOnKeyDown}
                />
              </div>
              <br />
              <div className="slds-form-element">
                <span className="slds-form-element__label dropdown-label">Search object by: </span>
                <span className="slds-radio">
                  <input
                    type="radio"
                    id="name"
                    value={Constants.OBJECT_TYPE__SEARCH_BY__NAME}
                    name="searchBy"
                    onChange={this.handleChangeSearchByOption}
                    checked={searchBy === Constants.OBJECT_TYPE__SEARCH_BY__NAME}
                  />
                  <label className="slds-radio__label" htmlFor="name">
                    <span className="slds-radio_faux" />
                    <span className="slds-form-element__label">Name</span>
                  </label>
                </span>
                <span className="slds-radio">
                  <input
                    type="radio"
                    id="customer-key"
                    value={Constants.OBJECT_TYPE__SEARCH_BY__CUSTOMER_KEY}
                    name="searchBy"
                    onChange={this.handleChangeSearchByOption}
                    checked={searchBy === Constants.OBJECT_TYPE__SEARCH_BY__CUSTOMER_KEY}
                  />
                  <label className="slds-radio__label" htmlFor="customer-key">
                    <span className="slds-radio_faux" />
                    <span className="slds-form-element__label">External key</span>
                  </label>
                </span>
              </div>
              <br />
              <button
                id="search-button"
                type="submit"
                className="slds-button slds-button_brand"
                onClick={this.handleSearchClick}
                disabled={!(searchQuery && selectedObjectType) || loading}
              >
                Search

              </button>
            </div>
          </div>

          {isClickedFirstTime ?
            null :
            (
              <div className="home-page-message" id="home-page-message" />
            )}
        </div>
        {isClickedFirstTime && (
          <Results
            data={data || []}
            marketingCloudStackNumber={marketingCloudStackNumber}
            isClickedFirstTime={isClickedFirstTime}
            loading={loading}
            isCurrentBusinessUnitId={isCurrentBusinessUnitId}
            currentBusinessUnitId={currentBusinessUnitId}
            handleSetSearchState={this.handleSetSearchState}
          />
        )}
      </>
    );
  }
}

Search.propTypes = {
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * object of current user
   * This prop will be passed from App.js component
   */
  user: PropTypes.instanceOf(Object).isRequired,
  /**
   * Responsible for showing/hiding data table when clicked
   * first time
   */
  isClickedFirstTime: PropTypes.bool.isRequired,
};

export default Search;
