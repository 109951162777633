import React, { Component } from 'react';
import './App.css';
import './App.scss';
import axios from 'axios';
import Cookies from 'js-cookie';
import Util from './util';
import Constants from './constants/Constants';
import NetworkErrorModal from './NetworkErrorModal';

import Overview from './containers/overview/Overview';
import NavigationBar from './components/NavigationBar/NavigationBar';

const user = Util.readObjectFromCookies(process.env.REACT_APP_SEARCH_STUDIO__SESSION_USER_KEY);

const justLoggedIn = Cookies.get('justLoggedIn');
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      navigator: Constants.NAVIGATION__OVERVIEW,
      globalNavigator: Constants.NAVIGATION__OVERVIEW,
      isClickedFirstTime: false,
    };
    this.clearState = {
      justLoggedIn: false,
    };
    this.state = {
      ...this.state,
      ...this.clearState,
    };

    this.axiosCancelToken = axios.CancelToken.source();
  }

  async componentDidMount() {
    // Handle connection status
    this.handleConnectionChange();
    /*
     * window.online and window.offline event listener
     * Take proper actions based on the network status
     */
    window.addEventListener(Constants.NETWORK_ONLINE, this.handleConnectionChange);
    window.addEventListener(Constants.NETWORK_OFFLINE, this.handleConnectionChange);

    // this.getSelections();

    // Checks if the user loaded the app for the first time after login
    if (justLoggedIn && justLoggedIn.toString() === 'true') {
      // Saves this state so the app knows the user logged in and we can set it back to false
      this.setState({ justLoggedIn: true });
      // Set the cookie back to false
      Cookies.set('justLoggedIn', false);
    }
  }

  /**
   * Updates isDisconnected state based on network status
   * @returns {void} Will setState for isDisconnected
   */
  handleConnectionChange = () => {
    this.setState({ isDisconnected: !navigator.onLine });
  }

  /**
   * General function for setting the app state
   * @param {object} newState - currently passed object
   * @param {function} callback - callback function
   * @returns {void}
   */
  handleSetAppState = (newState, callback) => {
    const { isSelectionMounted } = this.state;
    if (isSelectionMounted || !newState.origin) {
      this.setState(newState, callback);
    }
  };

  render() {
    const {
      error,
      isDisconnected,
      navigator,
      isClickedFirstTime,
    } = this.state;

    // throw an error
    if (error) {
      console.log(error);
      throw error;
    }

    /**
     * Check if the browser is 'online' and has the Internet access
     * If the browser is 'offline'; display a modal with a proper message
     */
    if (isDisconnected) {
      return (
        <NetworkErrorModal error={false} />
      );
    }

    /**
     * If no error then show main screen
     */
    if (navigator === Constants.NAVIGATION__OVERVIEW) {
      return (
        <div>
          <NavigationBar
            user={user}
            isClickedFirstTime={isClickedFirstTime}
          />
          <Overview
            handleSetAppState={this.handleSetAppState}
            user={user}
            isClickedFirstTime={isClickedFirstTime}
          />
        </div>
      );
    }
    return null;
  }
}

export default App;
