import React from 'react';
import PropTypes from 'prop-types';

/**
 * HTML for a network error modal
 * @param {object} param - An object with 'error' and 'cancelError' properties
 * @returns {object} The modal
 */
const NetworkErrorModal = ({ error, cancelError }) => (
  <>
    <section role="dialog" aria-modal="true" className="slds-modal slds-fade-in-open">
      <div className="slds-modal__container">
        <header className="slds-modal__header network-error-header">
          <h2 id="modal-heading-01" className="slds-modal__title slds-hyphenate">Network error</h2>

        </header>
        <div className="slds-modal__content slds-p-around_medium network-error-body">
          <p className="slds-m-top_x-small">Connecting to DESelect... Please check your internet connection.</p>
        </div>
        {error ?
          (
            <footer className="slds-modal__footer">
              <button
                type="button"
                id="cancelError"
                className="slds-button slds-button_neutral"
                onClick={() => cancelError()}
              >
                OK
              </button>
            </footer>
          ) :
          null}
      </div>
    </section>
    <div className="slds-backdrop slds-backdrop_open" />
  </>
);

NetworkErrorModal.propTypes = {
  /*
   * if error doesn't equal to a network error like 401, 404 etc. but if the browser is not connected a network,
   * it is true else it is false
   * this props is passed from App.js
   */
  error: PropTypes.bool.isRequired,
  /*
   * if error prop is true then it sets the App component's states (check the function for them) else it is null
   * this prop is a function and it is passed from App.js
   */
  cancelError: PropTypes.func,
};
export default NetworkErrorModal;
