
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const Util = {

  /**
   * Catch the 418 error, which corresponds to the password expired and the 403 error, which corresponds
   * to the inactive user.
   * @param {function} functionToExec - The function to call
   * @param {...*} param - Parameters passed to the function
   * @returns {*|void}
   * - If no error, then it will return the result of the execution of `functionToExec` with `param`.
   * - If error is `418` or `403`, this function will be blocking everything, it should never return any value.
   * - If error is something else, it throws the error.
   */
  catch418And403Error: async (functionToExec, ...param) => {
    try {
      const res = await functionToExec.call({}, ...param);

      // Return result as everything went fine
      return res;
    } catch (error) {
      // Text for swal pop up
      let title = '';
      let paragraph = '';

      // If error 418, handle it here
      if (
        error && error.request &&
        error.request.status &&
        error.request.status === 418
      ) {
        title = 'Password expired';
        paragraph = 'Your Marketing Cloud password has expired. ' +
          'Please log out of Marketing Cloud and re-login with the new password to continue using DESelect.';
      } else if (
        error && error.request &&
        error.request.status &&
        error.request.status === 403
      ) {
        title = 'Not authorized';
        paragraph = error.response.data || 'You do not have access.';
      } else {
        // Handle normally as it's not error 418 or 403
        throw error;
      }
      // If error was not thrown, display our message
      return Swal.fire({
        type: 'error',
        title: `<div class="error-title">${title}</div>`,
        html:
          `<p class="width_swal">
            ${paragraph}
          </p>`,
        // Remove all ways to close the alert
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        footer: '<div></div>',
      });
    }
  },

  /**
   * Get the API settings for the GET method
   * @param {object} cancelToken - The cancel token from axios
   * @returns {string} An object with 'withCredentials' set to true and the 'cancelToken'
   */
  apiGetCallSettings: cancelToken => ({
    withCredentials: true,
    cancelToken,
  }),

  /**
   * Get the API settings for the POST method
   * @param {object} cancelToken - The cancel token from axios
   * @returns {string} An object with 'withCredentials' set to true and the 'cancelToken' and 'headers'
   */
  apiPostCallSettings: cancelToken => ({
    withCredentials: true,
    cancelToken,
    headers: {
      'csrf-token': Cookies.get('csrf-token'),
      'token-secret': Cookies.get('token-secret'),
    },
  }),

  /**
   * Read an object from the cookie
   * @param {string} cookieName - Name of the cookie
   * @returns {object|undefined} The cookie or undefined if cookie name can't be find
   */
  readObjectFromCookies: (cookieName) => {
    // Get string from cookies
    const fullObjectStr = Cookies.get(cookieName);
    // console.log('cookie:' + fullObjectStr);
    if (fullObjectStr && fullObjectStr !== 'undefined') {
      // string for features is in format: j: {} --> drop first characters
      const objectStr = fullObjectStr.substr(2, fullObjectStr.length);
      // parse remaining string into object
      return JSON.parse(objectStr);
    }
    return undefined;
  },
};

export default Util;
